function galleryOpen(lang, images, entityId, startingImgId, variantId) {
  var gallery = document.getElementById('gallery');
  var footer = document.getElementById('indicator-container');
  var counter = 0;

  for (let index = gallery.children.length - 1; index >= 0; index--) {
    gallery.children[index].remove();
  }

  for (let index = footer.children.length - 1; index >= 0; index--) {
    footer.children[index].remove();
  }

  images.forEach(img => {

    if ((img['elementId'] === 3 || img['elementId'] === 6 || img['elementId'] === 8 || img['elementId'] === 27 || img['elementId'] === 28) && img['variantId'] == variantId) {

      let url = 'http://' + window.location.host + '/images/' + img["name"] + '-original.webp';
      let urlError = 'http://' + window.location.host + '/images/' + img["name"] + '.webp';
      var http = new XMLHttpRequest();

      var urlIndicator = 'http://' + window.location.host + '/images/' + img["name"] + '-thumb.webp';
      var id = img['id'];
      if (lang === 'bg') {
        var alt = img['contentBG'];
      } else if (lang === 'en') {
        var alt = img['contentEN'];
      }
      var item = document.createElement('div');

      if (img['id'] === startingImgId) {
        item.classList.add('carousel-item', 'active', 'h-100', 'w-100');
      } else {
        item.classList.add('carousel-item', 'h-100', 'w-100');
      }

      var header = document.createElement('div');
      header.classList.add('modal-header', 'border-0');
      var title = document.createElement('h5');
      title.classList.add('text-center', 'd-none');

      if (lang === 'bg') {
        title.innerHTML = img['contentBG'];
      } else if (lang === 'en') {
        title.innerHTML = img['contentEN'];
      }

      var closeBtn = document.createElement('button');
      closeBtn.classList.add('text-white', 'close');
      closeBtn.setAttribute('type', 'button');
      closeBtn.setAttribute('data-dismiss', 'modal');
      closeBtn.setAttribute('aria-label', 'Close');
      closeBtn.style = 'z-index:11';


      var closeIcon = document.createElement('span');
      closeIcon.setAttribute('aria-hidden', true);
      closeIcon.innerHTML = '&#x2715';
      closeBtn.appendChild(closeIcon);
      header.appendChild(closeBtn);
      item.appendChild(header);

      var body = document.createElement('div');
      body.classList.add('h-100', 'w-100', 'd-flex', 'justify-content-center');
      var overlay = document.createElement('div');
      var img = document.createElement('img');
      img.classList.add('pic-height');
      img.setAttribute('style', "object-fit: scale-down");
      img.setAttribute('onerror', "this.src = '" + urlError + "'");
      if (lang === 'bg') {
        img.setAttribute('alt', alt);
      } else if (lang === 'en') {
        img.setAttribute('alt', alt);
      }
      img.src = url;
      var titleBox = document.createElement('div');
      titleBox.classList.add('gallery-image');
      titleBox.appendChild(title);
      overlay.appendChild(img);
      body.appendChild(overlay);
      body.appendChild(titleBox);
      item.appendChild(body);
      gallery.appendChild(item);

      var indicator = document.createElement('li');
      indicator.setAttribute('data-target', "#carouselExampleIndicators");
      indicator.setAttribute('data-slide-to', counter);
      counter++;

      var indicatorImg = document.createElement('img');
      indicatorImg.setAttribute('src', urlIndicator);
      indicatorImg.setAttribute('alt', alt);
      indicatorImg.classList.add('d-block', 'w-100', 'product-indicator-img', 'mb-1');

      indicator.appendChild(indicatorImg);
      footer.appendChild(indicator);
    }
  });
}
var search = document.querySelector('input[id=search]');
var suggestions = document.querySelector('div[id=suggesstion-box]');

search.oninput = function () {
  var _token = $("input[name=_token]").val();

  if (search.value.length >= 3) {
    $.ajax({
      url: "/autocomplete",
      type: 'post',
      dataType: 'json',
      data: { search: search.value, _token: _token },
      success: function (response) {
        var toRemove = suggestions.children;
        for (let index = toRemove.length - 1; index >= 0; index--) {
          toRemove[index].remove();
        }
        var list = document.createElement('ul');
        list.style = 'list-style:none';
        list.classList.add('pl-0', 'w-100');
        var subs = [];
        console.log(response);
        response.forEach(res => {
          console.log(res[0]);
          subs.push(res[0]['subcategoryId']);
        });

        if (response.length > 0) {
          pages.forEach(page => {
            subcatеgories.forEach(subcategory => {
              if (page['categoryId'] === subcategory['id']
                && page['level'] === 'subcategory'
                && page['type'] === 'product'
                && subs.includes(subcategory['id'])) {

                var itemConttitle = document.createElement('li');
                itemConttitle.classList.add('pl-1', 'font-weight-bold');
                itemConttitle.style = 'font-size:20px;';

                if (lang === 'en') {
                  itemConttitle.innerHTML = subcategory['nameEN'];
                } else if (lang === 'bg') {
                  itemConttitle.innerHTML = subcategory['name'];
                }

                list.appendChild(itemConttitle);

                response.forEach(item => {
                  if (item[0]['subcategoryId'] === subcategory['id']) {
                    var link = document.createElement('a');
                    link.classList.add('text-decoration-none', 'search-link');
                    link.href = '/' + lang + item[0]['productURL'] + item[0]['id'];
                    var itemCont = document.createElement('li');
                    itemCont.classList.add('pl-2');

                    if (lang === 'en') {
                      itemCont.innerHTML = item[0]['contentEN'];
                    } else if (lang === 'bg') {
                      itemCont.innerHTML = item[0]['contentBG'];
                    }

                    link.appendChild(itemCont)
                    list.appendChild(link);
                  }
                });
              }
            });

            subcatеgories.forEach(subcategory => {
              if (page['categoryId'] === subcategory['id']
                && page['level'] === 'subcategory'
                && page['type'] === 'article'
                && subs.includes(subcategory['id'])) {
                var itemConttitle = document.createElement('li');
                itemConttitle.classList.add('pl-1', 'font-weight-bold');
                itemConttitle.style = 'font-size:20px;';
                if (lang === 'en') {
                  itemConttitle.innerHTML = subcategory['nameEN'];
                } else if (lang === 'bg') {
                  itemConttitle.innerHTML = subcategory['name'];
                }
                list.appendChild(itemConttitle);

                response.forEach(item => {
                  if (item[0]['subcategoryId'] === subcategory['id']) {
                    var link = document.createElement('a');
                    link.classList.add('text-decoration-none', 'search-link');
                    link.href = '/' + lang + item[0]['productURL'] + '/' + item[0]['id'];
                    var itemCont = document.createElement('li');
                    itemCont.classList.add('pl-2');

                    if (lang === 'en') {
                      itemCont.innerHTML = item[0]['contentEN'];
                    } else if (lang === 'bg') {
                      itemCont.innerHTML = item[0]['contentBG'];
                    }

                    link.appendChild(itemCont)
                    list.appendChild(link);
                  }
                });
              }
            });
          });

          suggestions.appendChild(list);
        } else {
          itemConttitle = document.createElement('li');
          itemConttitle.classList.add('pl-1', 'font-weight-bold');
          itemConttitle.style = 'font-size:20px;';

          if (lang === 'en') {
            itemConttitle.innerHTML = 'There are no results found.';
          } else if (lang === 'bg') {
            itemConttitle.innerHTML = 'Няма намерени резултати';
          }
          list.appendChild(itemConttitle);
          suggestions.appendChild(list);
        }
      }
    });
  } else {
    var toRemove = suggestions.children;

    for (let index = toRemove.length - 1; index >= 0; index--) {
      toRemove[index].remove();
    }
  }
};

var body = document.querySelector('body');
body.onclick = function changeContent() {
  var toRemove = suggestions.children;

  for (let index = toRemove.length - 1; index >= 0; index--) {
    toRemove[index].remove();
  }
}

function agreement() {
  var element = document.getElementById("agreement-modal");
  var today = new Date();
  var time = today.setHours(today.getHours() + 6);
  document.cookie = "user=true; expires=" + today + ";path=/";
  element.remove();
};
function cancel() {
  var element = document.getElementById("agreement-modal");
  element.remove();
};

if (document.getElementsByClassName('program') != null) {
  var spans = document.getElementsByClassName('program');
  for (let index = 0; index < spans.length; index++) {
    var element = spans[index];
    element.querySelectorAll('p').forEach(e => {
      e.classList.add('m-0');
    });
  }
}
// Special carousel
if (document.getElementById('secondary-slider') != null) {
  document.addEventListener('DOMContentLoaded', function () {
    new Splide('#secondary-slider', {
      fixedWidth: 110,
      height: 110,
      gap: 17,
      rewind: true,
      pagination: false,
      autoplay: true,
      breakpoints: {
        640: {
          fixedWidth: 120,
        },
      }
    }).mount();
  });
}
if (document.getElementById('related-slider') != null) {
  document.addEventListener('DOMContentLoaded', function () {
    new Splide('#related-slider', {
      perPage: 4,
      gap: 17,
      rewind: true,
      pagination: false,
      autoplay: true,
      cover: true,
      breakpoints: {
        '767': {
          perPage: 2,
        },
        '600': {
          perPage: 1,
        }
      },
    }).mount();
  });
}

document.addEventListener("DOMContentLoaded", function () {
  global_carousel__ctrl();
});


function global_carousel__ctrl() {
  var elms = document.getElementsByClassName("product-slider");
  var elms2 = document.getElementsByClassName("product-slider-thumb");

  for (var i = 0; i < elms.length; i++) {

    var secondarySlider = new Splide(elms2[i], {
      fixedWidth: 115,
      height: 80,
      isNavigation: true,
      pagination: false,
      arrows: false,
      gap: 15,
      rewind: true,
      autoplay: true,
      perPage: 5,
      breakpoints: {
        '600': {
          fixedWidth: 66,
          height: 40,
        }
      },
    }).mount();

    elms2[i].getElementsByClassName('splide__track')[0].style = 'width: 100%; margin-left: auto; margin-right: auto;';
    var primarySlider = new Splide(elms[i], {
      type: 'fade',
      pagination: false,
      arrows: false,
      height: 333,
      fixedWidth: 600,
      lazyLoad: 'nearby',
      rewind: true,
      autoplay: true,
      breakpoints: {
        '991': {
          fixedWidth: false,
          height: 280,
        },
        '600': {
          fixedWidth: false,
          height: 200,
        }
      },
    });




    primarySlider.sync(secondarySlider).mount();

  }
}

//   video script
/*
* Light YouTube Embeds by @labnol
* Credit: https://www.labnol.org/
*/

function labnolIframe(div, pageId) {
  var iframe = document.createElement('iframe');

  iframe.setAttribute(
    'src',
    'https://www.youtube.com/embed/' + div.dataset.id + '?autoplay=1&rel=0&mute=1'
  );
  iframe.setAttribute('frameborder', '0');
  iframe.setAttribute('allowfullscreen', '1');
  iframe.setAttribute(
    'allow',
    'autoplay'
  );

  if (pageId == 'index') {
    iframe.classList.add('iframe-wide');
  }
  div.parentNode.replaceChild(iframe, div);
}

function initYouTubeVideos() {
  var playerElements = document.getElementsByClassName('youtube-player');

  for (var n = 0; n < playerElements.length; n++) {
    var videoId = playerElements[n].dataset.id;
    var pageId = playerElements[n].getAttribute('data-page');
    var div = document.createElement('div');
    div.setAttribute('data-id', videoId);
    playerElements[n].appendChild(div);
    if (pageId == 'index') {
      indexVid(videoId, playerElements[n]);
    } else {
      labnolIframe(div, pageId);
    }
  }
}

document.addEventListener('DOMContentLoaded', initYouTubeVideos);

function slide(direction) {
  var container = document.getElementById('table-scroll');
  scrollCompleted = 0;
  var slideVar = setInterval(function () {
    if (direction == 'left') {
      container.scrollLeft -= 10;
    } else {
      container.scrollLeft += 10;
    }
    scrollCompleted += 10;
    if (scrollCompleted >= 100) {
      window.clearInterval(slideVar);
    }
  }, 50);
}

function closeNav() {
  var nav = document.getElementsByClassName('navbar-collapse');
  var toggler = document.getElementsByClassName('navbar-toggler');
  if (nav[0].classList.contains('show')) {
    nav[0].classList.remove('show');
    toggler[0].classList.add('collapsed');
  }
}

function changeIcon() {
  var navicon = document.getElementsByClassName('nav-icon');
  var toggler = document.getElementsByClassName('navbar-toggler');

  if (toggler[0].classList.contains('collapsed')) {
    navicon[0].classList.remove('navbar-toggler-icon');
    navicon[0].classList.add('close-icon');

  } else {
    navicon[0].classList.remove('close-icon');
    navicon[0].classList.add('navbar-toggler-icon');
  }

}

function indexVid(videoId, div) {
  var tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  tag.setAttribute("rel", "dns-prefetch");
  var firstScriptTag = document.getElementById('youbs');
  firstScriptTag.appendChild(tag);
  onYouTubeIframeAPIReady(videoId, div);
}

var player;
var players = [];
function onYouTubeIframeAPIReady(videoId, div) {

  if (typeof (YT) !== 'undefined') {
    var playerElements = document.getElementsByClassName('youtube-player');
    for (var i = 0; i < playerElements.length; i++) {
      videoId = playerElements[i].dataset.id;
      player = new YT.Player('player' + i, {
        videoId: videoId,
        playerVars: {
          'playsinline': 1,
          'autoplay': 1,
          'mute': 1,
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': getStatus
        }
      });
      players.push(player);
    }
  }
}

function onPlayerReady(event) {
  event.target.playVideo();
}

function getStatus() {
  var carousel = document.getElementById('carouselBig');

  var sStatus;
  for (var i = 0; i < players.length; i++) {
    player = players[i];

    try {
      if (player) {
        if (typeof player.getPlayerState === 'function') {
          sStatus = player.getPlayerState();
        }
      } else {
        console.error('Error: player is not defined.');
      }
    } catch (error) {
      console.error('An unexpected error occurred:', error.message);
    }

    if (sStatus === 0) {
      $('.carousel').carousel('next');
      player.playVideo();
      player.stopVideo();
      $('.carousel').carousel('pause');

    }
  }
}

// function stopVideo() {
//     player.stopVideo();
//

window.galleryOpen = galleryOpen;
window.agreement = agreement;
window.cancel = cancel;
window.slide = slide;
window.closeNav = closeNav;
window.changeIcon = changeIcon;